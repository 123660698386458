
.font_h1 {font-size: 2rem;
font-family: 'Noto Sans', sans-serif;
font-weight: lighter;}

.font_h2 {font-size: 1.5rem;
font-family: 'Noto Sans', sans-serif;
font-weight: 600;}

.font_h3 {font-size: 1.17rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h4 {font-size: 1.25rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h5 {font-size: 0.83rem;
font-family: 'Noto Sans', sans-serif;
font-weight: normal;}

.font_h6 {font-size: 0.625rem;
font-family: 'Noto Sans', sans-serif;}

.font_body {font-size: 1.6rem;
font-family: 'Noto Sans', sans-serif;}

.font_subtitle {font-size: 1.4rem;
font-family: 'Noto Sans', sans-serif;}

.font_button {font-size: 0.75rem;
font-family: 'Noto Sans', sans-serif;}

.font_caption {font-size: 1.2rem;
font-family: 'Noto Sans', sans-serif;}

.font_overline {font-size: 1rem;
font-family: 'Noto Sans', sans-serif;}

.color_primary {background: #0000f8;
color: #fff;}

.color_secondary {background: #f6f40d;
color: #000;}

.color_button {background: #f6f40d;
color: #00013e;}

.color_title {background: transparent;
color: #000000;}

.page_color {background: #00013e;
color: #000000;}

.color_active {background: transparent;
color: #0000f8;}

.contact_front_d {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;}

.contact_back_d .list-group {border: 1px solid #f6f40d;}

.contact_back_d .contact_back_d_header {background: linear-gradient(90deg, rgba(239,207,151,1) 0%, RGBA(250, 239, 210, 1) 100%);
color: rgba(149, 101, 39, 1);}

.contact_back_d .contact_back_d_item {background: #00013e;
color: #fff;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.announcement_body {color: #fff;}

.announcement_header img {filter: drop-shadow(1px 2px 0px black);}

.announcement_header_title {color: #f6f40d;}

.announcement_btnClose {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.header_topBar {background: #01052f;
color: #fff;
box-shadow: 1px 0px 3px 0px rgba(255,255,255,.3);}

.header_menu {color: #fff;}

.header_menu .header_menu_page .header_menu_content.active {background: transparent;
color: #f6f40d;
border-bottom: 3px solid #f6f40d;}

.banking_dropdown a li,.claim_dropdown li {background: #fff;
color: 8080880;}

.banking_dropdown a.active li,.claim_dropdown a.active li {background: #00013e;
color: #f6f40d;}

.header_btnLogin {background: #f6f40d;
color: #000;
border-radius: 5px 5px 5px 5px;
border-top: 0px none ;
border-bottom: 0px none ;
border-left: 0px none ;
border-right: 0px none ;}

.header_btnRegister {background: red;
color: #ffffff;
border-radius: 5px 5px 5px 5px;}

.header_btnLogout {background: red;
color: #ffffff;
border-radius: 5px 5px 5px 5px;}

.header_btnRestore {background: red;
color: #fff;
border-radius: 5px 5px 5px 5px;}

.header_btnBanking {background: #f6f40d;
color: #000;
border-radius: 5px 5px 5px 5px;}

.header_btnPromoClaim {background: #f6f40d;
color: #000;
border-radius: 5px 5px 5px 5px;}

.header_topBar+.header_banking_info {background: #000023;
color: #fff;
box-shadow: inset 0px 0px 5px 0px rgb(0 0 0 / 50%);}

.header_sideBar_topBar {background: #00013e;
color: #fff;
filter: drop-shadow(-3px 0px 2px rgba(255,255,255,.3));}

.navbar-toggler {background: transparent;
color: #fff;}

.header_sideBar .header_sideBar_body {background: #00013e;
color: #fff;}

.header_sideBar_menu .header_menu_content, .header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {background: transparent;
color: #fff;
border-bottom: 1px solid #fff;
margin: 0 .85rem;}

.header_sideBar_menu .header_menu_content.active {background: transparent;
color: #f6f40d;
border-bottom: 1px solid #fff;}

.header_banking_info .header_banking_menu_list {color: #999;}

.header_banking_info .header_banking_menu_list .active {color: #f6f40d;}

.header_banking_info_left .header_balance .header_balance_amount span {background: #f6f40d;
color: #000;}

.header_banking_info_left .header_balance .header_balance_amount.coupon_balance span {background: transparent;
color: #fff;
outline: 1px solid #f6f40d;}

.header_topBar_wrap .header_banking_info .header_balance {background: #f6f40d;}

.header_banking_info .header_balance_icon {color: #fff;}

#promoClaimModal label {color: #00013e;}

#promoClaimModal .filterTab .filterTab_selection.active {background: #00013e;
color: #f6f40d;}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {background: transparent;
color: #fff;
outline: 1px solid #f6f40d;}

.header_banking_info_left .header_balance_currency {margin-bottom: .15rem;}

.footer {background: #000;
color: #fff;
box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
border-top: 1px solid #0000f8;}

.footer_menu_wrap {border-bottom: 1px dashed #fff;}

.footer_payment_method .footer_info_img li:first-of-type img {filter: brightness(0) invert(1);}

.btnCancel {background: transparent;
color: #000;
border: 1px solid #f6f40d;
border-radius: 5px 5px 5px 5px;}

.modal .confirm_button {background: #f6f40d;
color: #00013e;
border-radius: .375rem;
padding: .375rem .75rem;}

.modal .cancel_button {background: transparent;
color: #000;
border: 1px solid #f6f40d;
border-radius: .375rem;
padding: .375rem .75rem;}

.tab_pills_color.nav-pills .nav-link:hover {color: #0000f8;}

.footer_help a:hover, .header_banking_menu_list a:hover, .header_menu_right .header_menu_page:hover, .walletBar_selection:hover, .banking_dropdown a li:hover,.header_menu_page .header_text:hover,.claim_dropdown li:hover {color: #f6f40d;}

.header_menu_page .header_text:hover {color: #f6f40d;}

.banking_dropdown a li:hover,.claim_dropdown li:hover,.header_menu_dropdownItem:hover,.header_menu_content.header_menu_dropdownItem.active {background: #00013e;
color: #f6f40d;}

.scrollToTopButton {background: #0000f8;
color: rgba(255, 255, 255, 1);
border-radius: 50%;
padding: .375rem .75rem;}

.swiper .swiper-pagination-bullet {background: #f6f40d;}

.alertMessage_content svg {color: #00013e;}

.form_required_input,.register_error_msg,.forgotPassword_error_msg,.forgotUsername_error_msg,.error_msg,.form_required,.displayQR_row label,.input_error_msg {color: #f6f40d;}

.register_form_reminder_title, .forgotPassword_form_reminder_title, .forgotUsername_form_reminder_title {color: #f6f40d;}

.forgot_content .forgot_content_username, .forgot_content .forgot_content_password {color: #f6f40d;}

.btnCancelPromotion {background: #f6f40d;
color: #00013e;}

.btnAfterVerify {background: #00013e;
color: #fff;
border: 1px solid #f6f40d;}

.announcement_header_title span {margin-bottom: .25rem;}

.header_sideBar.offcanvas {background: #00013e;}

.floatingIcon_closeButton {color: #fff;}

.gameWallet_accordion .gameWallet_accordion_icon {background: #f6f40d;
color: #000;}

.header_point span:nth-child(2) {color: #fff;}

.header_bottom_row {color: #fff;}

.modal-dialog .form_required_input, .modal-dialog .register_error_msg, .modal-dialog .form_required, .modal-dialog .input_error_msg {color: red;}

#GameTermsModal .modal-header {background: linear-gradient(to top, #00013e, #0000f8);
color: #fff;}

#GameTermsModal .modal-header .btn-close {background: transparent var(--btn-close-bg-white) center/1em auto no-repeat;}

.more_menu .dropdown_more_content {background: #f1f1f1;
border-radius: .5rem;}

.dropdown_more_content_container a {filter: none;}

.dropdown_more_content a:not(:first-child) {margin-top: .5rem;}

.header_menu_dropdownItem {background: #fff;
color: #000;
border-radius: .5rem;}
