
.speakerAnnouncement_body {background: #00013e;
color: #fff;}

.main_game {background: #00013e;
padding: 1rem 0 2rem;}

.main_gameSelection .main_gameSelection_menu {background: #000023;
color: #999;
border-radius: 10px;
margin: 5px;}

.main_gameSelection .main_gameSelection_menu.active {background: #f6f40d;
color: #00013e;}

.main_game_container {background: #000023;
color: #fff;
border-radius: 10px;}

.main_gameSelection .main_gameSelection_menu .main_gameSelection_image {margin: 0.5rem;}

#Section_gameType .Section_gameType_body {background: #00013e;
padding-top: 2.5rem;}

#Section_gameType .Section_gameType_body .section_title {font-weight: normal;}

#Section_gameType .card_image_wrap {background: rgba(0, 0, 0, 0);
border-radius: 5px 5px 5px 5px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#Section_gameType .content_container {background: rgba(0, 0, 0, 0);
border-radius: 0px 0px 5px 5px;}

#Section_Popular_Games_We_Love .Section_Popular_Games_We_Love_body {background: #00013e;
color: #fff;}

#Section_Popular_Games_We_Love .Section_Popular_Games_We_Love_body .section_title {font-weight: normal;}

#Section_Popular_Games_We_Love .card_image_wrap {background: rgba(0, 0, 0, 0);
border-radius: 5px 5px 5px 5px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#Section_Popular_Games_We_Love .content_container {background: rgba(0, 0, 0, 0);
border-radius: 0px 0px 5px 5px;}

.rankingList_body {background: #00013e;
color: #fff;
padding: 1.5rem 0 1.5rem;}

.rankingList_body .section_title {font-weight: normal;}

.rankingList_table {background: #000023;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

.rankingList_table_header {background: linear-gradient(to top, #00013e, #0000f8);
color: #f6f40d;
font-weight: normal;
border-radius: 10px 10px 10px 10px;
margin: 0;
padding: 1rem;}

.rankingList_table_container .rankingList_table_header+.rankingList_table_body {padding: 0;}

.rankingList_table_row {background: #000023;
color: #000;
border-bottom: 1px solid #fff;
margin-left: 1rem;
margin-right: 1rem;}

.rankingList_username {color: #fff;
font-weight: bold;}

.rankingList_info {color: #f6f40d;}

.rankingList_amount {color: #fff;
font-weight: bold;}

.rankingList_m_table .rankingList_tableTab {background: linear-gradient(to top, #00013e, #0000f8);
border: 0;
border-radius: 10px 10px 10px 10px;
filter: drop-shadow(0px 1px 3px rgba(0,0,0,.5));
padding: 0.65rem;}

.rankingList_tableTab .rankingList_table_selection {background: transparent;
color: #fff;
font-weight: 600;
border-radius: 10px;}

.rankingList_tableTab .rankingList_table_selection.active {background: #fff;
color: #00013e;
border: 0;
border-radius: 10px;}

.rankingList_m_table {background: #000023;
border-radius: 10px;
padding: 1rem;}

.rankingList_m_table .rankingList_table {background: #000023;
border-radius: 10px 10px 10px 10px;
filter: none;
padding: 0;}

.rankingList_m_table .rankingList_table_body {margin-top: .65rem;}

.rankingList_m_table .rankingList_table_row {background: #000023;
border-radius: 0;
padding: 1rem 0;}

#Section_mainPage_promotion .Section_mainPage_promotion_body {background: #00013e;
color: #fff;
padding-top: 1.5rem;}

#Section_mainPage_promotion .Section_mainPage_promotion_body .section_title {font-weight: normal;}

#Section_mainPage_promotion .card_image_wrap {background: transparent;
border-radius: 5px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#Section_Service {background: #00013e;
color: #fff;
padding: 1.5rem 0 3rem;}

#Section_Service .section_title {font-weight: normal;}

.contactUs_body {background: #00013e;
color: #fff;}

#contactUs_title.pageTitle {background: #00013e;
color: #fff;
padding-top: 0;
padding-bottom: 2.5rem;}

.contact_card.card {background: #000023;
color: #fff;
border-radius: 0.8rem;
border-top: 1px solid #f6f40d;
border-bottom: 1px solid #f6f40d;
border-left: 1px solid #f6f40d;
border-right: 1px solid #f6f40d;
margin: 10px;
padding: 1rem;}

#contactUs_title_m {background: transparent;
color: #fff;
padding: 2rem 1.25rem 1rem;}

.contact_container_m .contact_card.card {background: transparent;
color: #fff;
border-radius: 0;
border-top: 0;
border-bottom: 1px solid #808080;
border-left: 0;
border-right: 0;
margin: 10px 10px 0 10px;
padding: 0 1rem;}

#promotion_title {background: #00013e;
color: #fff;}

#Section_promoContainer .Section_promoContainer_body {background: #00013e;}

#Section_promoContainer .card_container {border-bottom: 1px dashed #fff;
padding: 0.8rem 1.5rem;}

#Section_promoContainer .card_image_wrap {border-radius: 0;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#Section_promoContainer .content_container {background: rgba(0, 0, 0, 0);}

#Section_promoContainer .card_content_title {color: #f6f40d;}

#Section_promoContainer .button_action1 {background: #f6f40d;
color: #000;
border: 0;
border-radius: 5px 5px 5px 5px;}

#Section_promoContainer .button_action2 {background: red;
color: rgba(255, 255, 255, 1);
border: 0;
border-radius: 5px 5px 5px 5px;}

#Section_promoContainer .filterTab {background: #fff;
border-radius: 25px 25px 25px 25px;
margin-top: 2rem;
padding: 0.5rem;}

#Section_promoContainer .filterTab .filterTab_selection {padding: 0.5rem 1rem;}

#Section_promoContainer .filterTab .filterTab_selection.active {background: #00013e;
color: #f6f40d;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part {border: 1px solid #808080;
border-radius: 5px 5px 5px 5px;}

#Section_promoContainer .promotionCode_part label {background: #fff;
color: #000;}

#Section_promoContainer .promotionCode_part .promotionCode_content {background: #333;
color: #fff;}

#Section_promoContainer .promotionTag_part label {background: #191960;
color: #fff;
border-radius: 5px 5px 5px 5px;
margin-right: .5rem;
padding: .25rem .5rem;}

#Section_promoContainer .Section_promoContainer_body {background: #00013e;}

#Section_promoContainer .card_container {border-bottom: 1px dashed #fff;
padding: 0.8rem 1.5rem;}

#Section_promoContainer .card_image_wrap {border-radius: 0;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#Section_promoContainer .content_container {background: rgba(0, 0, 0, 0);}

#Section_promoContainer .card_content_title {color: #f6f40d;}

#Section_promoContainer .button_action1 {background: #f6f40d;
color: #000;
border: 0;
border-radius: 5px 5px 5px 5px;}

#Section_promoContainer .button_action2 {background: red;
color: rgba(255, 255, 255, 1);
border: 0;
border-radius: 5px 5px 5px 5px;}

#Section_promoContainer .filterTab {background: #fff;
border-radius: 25px 25px 25px 25px;
margin-top: 2rem;
padding: 0.5rem;}

#Section_promoContainer .filterTab .filterTab_selection {padding: 0.5rem 1rem;}

#Section_promoContainer .filterTab .filterTab_selection.active {background: #00013e;
color: #f6f40d;
border-radius: 20px 20px 20px 20px;}

#Section_promoContainer .promotionCode_part {border: rgba(189, 0, 1, 1);
border-radius: 1px solid #808080;}

#Section_promoContainer .promotionCode_part label {background: #fff;
color: #000;}

#Section_promoContainer .promotionCode_part .promotionCode_content {background: #333;
color: #fff;}

#Section_promoContainer .promotionTag_part label {background: #191960;
color: #fff;
border-radius: 5px 5px 5px 5px;
margin-right: .5rem;
padding: .25rem .5rem;}

.help_body {background: #00013e;
padding: 0 0 3rem;}

#help_title {background: transparent;
color: #fff;}

.help_accordion {padding: 0 1.5rem;}

.help_accordion .accordion-item .accordion-button {background: #000023;
color: #fff;
border-radius: 5px 5px 5px 5px;}

.help_accordion .accordion-item .accordion-button:not(.collapsed) {background: linear-gradient(0deg,#00013e,#0000f8);
color: #f6f40d;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.help_accordion .accordion-item .accordion-collapse {border: 1px solid #000023;
border-radius: 0 0 5px 5px;
border-top: 0;}

.help_accordion .accordion-item .accordion-body {background: #000023;
color: #fff;
border-radius: 0 0 5px 5px;}

.login_body {background: #000023;
color: #fff;}

#login_title_d {background: #00013e;
color: #f6f40d;
font-weight: bold;}

#login_title_m {background: transparent;
color: #f6f40d;
font-weight: normal;}

.login_container {background: #00013e;
color: #fff;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);}

.login_form .form-label {color: #fff;}

.login_btnLogin {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_body {background: #000023;
color: #fff;}

#register_title_d {background: #00013e;
color: #f6f40d;
font-weight: bold;}

#register_title_m {background: transparent;
color: #f6f40d;
font-weight: normal;}

.register_container {background: #00013e;
color: #fff;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);}

.register_stepper button {background: rgb(224, 224, 224);
color: #000;}

.register_stepper .register_stepContainer.active button {background: rgba(57, 181, 74, 1);
color: rgba(255,255,255, 1);}

.register_stepContainer:not(:first-child):before {background: rgb(224, 224, 224);}

.register_stepContainer.active:not(:first-child)::before {background: green;}

.register_form .form-label {color: #fff;}

.register_btnNext {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_btnBack {background: transparent;
color: #fff;
border: 1px solid #f6f40d;
border-radius: 5px 5px 5px 5px;}

.register_btnDepositNow {background: red;
color: #fff;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.register_btnSend {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_body {background: #000023;
color: #fff;}

#forgotPassword_title_d {background: #00013e;
color: #f6f40d;
font-weight: bold;}

#forgotPassword_title_m {background: transparent;
color: #f6f40d;
font-weight: normal;}

.forgotPassword_container {background: #00013e;
color: #fff;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);}

.forgotPassword_form .form-label {color: #fff;}

.forgotPassword_btnNext {background: #f6f40d;
color: #000;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_btnBack {background: transparent;
color: #fff;
border: 1px solid #f6f40d;
border-radius: 5px 5px 5px 5px;}

.forgotPassword_btnSubmit {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotPassword_btnSend {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotUsername_body {background: #000023;
color: #fff;}

#forgotUsername_title_d {background: #00013e;
color: #f6f40d;
font-weight: bold;}

#forgotUsername_title_m {background: transparent;
color: #f6f40d;
font-weight: normal;}

.forgotUsername_container {background: #00013e;
color: #fff;
border-radius: 15px 15px 15px 15px;
filter: drop-shadow(rgb(0, 0, 0) 0px 1px 3px);}

.forgotUsername_form .form-label {color: #fff;}

.forgotUsername_btnSubmit {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.forgotUsername_btnSend {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.myProfile_body {background: #00013e;
color: #fff;}

#myProfile_title {background: transparent;
color: #f6f40d;}

.myProfile_mySetting_row {background: #000023;}

.myProfile_myAccount_row {background: #000023;}

.myProfile_myAccount_row svg {color: #f6f40d;}

.slots_body {background: #00013e;}

#slot_title {background: transparent;
color: #fff;
font-weight: bold;}

.slots_body .title_container a+div select {border-radius: 20px;}

.gameProviderTab_container {background: transparent;
border-radius: 5px 5px 5px 5px;}

.gameProviderTab .gameProviderTab_selection {background: rgb(224 222 222);
border-radius: 10px 10px 10px 10px;
border-top: 1px solid #0000f8;
border-bottom: 1px solid #0000f8;
border-left: 1px solid #0000f8;
border-right: 1px solid #0000f8;}

.gameProviderTab .gameProviderTab_selection.active {background: rgba(255, 255, 255, 1);
color: rgb(0, 0, 0, 1);}

.gameProviderTab_container button {background: transparent;
color: #0000f8;}

.slotTypeTab {background: #fff;
border-radius: 20px 20px 20px 20px;}

.slotTypeTab .slotTypeTab_selection.active {background: #00013e;
color: #f6f40d;
border-radius: 20px 20px 20px 20px;}

#slotGameContainer .button_action1 {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

#slotGameContainer .card_image_wrap {border-radius: 5px;
filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#slotGameContainer .content_container {background: rgba(255, 255, 255, 0);
color: #fff;
border-radius: 0px 0px 5px 5px;}

.mslot_container {margin: 4rem 10rem;}

.mslot_container_m {margin: 0 5rem 1rem;}

.mslot_container .mslot_wrap {background: transparent;
color: #fff;
border-radius: 0.5rem;
box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 1);;
padding: 3.5rem 2rem;}

.mslot_container_m .mslot_wrap {background: transparent;
color: #fff;
border-radius: 0;
box-shadow: none;
padding: 1.5rem;}

.mslot_userInfo label {color: #f6f40d;;}

#mslotGameContainer .mslotGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#Section_1675926801290 .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#mslotGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#casinoGameContainer .casinoGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#casinoGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#casinoGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#sportGameContainer .sportGameContainer_body {background: #00013e;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;
padding-top: 1.5rem;}

#sportGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#sportGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#esportGameContainer .sportGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#esportGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#esportGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#fishingGameContainer .fishingGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#fishingGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#fishingGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#lotteryGameContainer .lotteryGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#lotteryGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#lotteryGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#boardGameContainer .boardGameContainer_body {background: #00013e;
padding-top: 1.5rem;}

#boardGameContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#boardGameContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

#othersContainer .othersContainer_body {background: #00013e;
padding-top: 1.5rem;}

#othersContainer .card_image_wrap {filter: drop-shadow(rgb(0, 0, 0) 2px 3px 3px);}

#othersContainer .content_container {background: rgba(231, 231, 231, 0);
color: #fff;}

.wallet_body {background: #00013e;}

.wallet_body .pageTitle {background: transparent;
color: #fff;}

.wallet_container_title {background: linear-gradient(0deg,#00013e,#0000f8);
color: #fff;}

.walletBar {background: #000023;
color: #fff;
filter: drop-shadow(2px 3px 3px rgb(0,0,0,1));
border-top: 1px solid #0000f8 ;
border-bottom: 1px solid #0000f8 ;
border-left: 1px solid #0000f8 ;
border-right: 1px solid #0000f8 ;}

.walletBar_userInfo {color: #fff;}

.walletBar_userVerification,.walletBar_point,.walletBar_walletUserQrInfo {background: #00013e;}

.walletBar_selection.active {background: rgba(0, 0, 0, 0);
color: #f6f40d;}

.wallet_container {border: 1px solid #0000f8;
border-radius: 10px 10px 10px 10px;
filter: drop-shadow(2px 3px 3px rgb(0,0,0,1));}

.wallet_container_wrap {background: #000023;
color: #fff;
border-radius: 0 0 10px 10px;}

.wallet_depositMethod img+div,.deposit_channel_row img+div {color: #a6a8ab;}

.wallet_depositMethod img+div.active,.deposit_channel_row img+div.active {color: #fff;}

.wallet_btnSubmit,.wallet_gameWallet_btnAllIn,.wallet_gameWallet_btnRefresh {background: #f6f40d;
color: #00013e;
border-radius: 5px 5px 5px 5px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.wallet_container .form-control:disabled {background: #b3b3b3;
color: rgba(0, 0, 0, 1);}

.wallet_reminder_container {background: #00013e;
color: #fff;}

.wallet_reminder_title {color: #f6f40d;}

.table.wallet_history_table thead,.myAccount_notification_header,.myAccount_bankingDetails_tableHeader {background: linear-gradient(0deg,#00013e,#0000f8);
color: rgba(255,255,255, 1);
border-top: 0px solid transparent;
border-bottom: 0px solid transparent;
border-left: 0px solid transparent;
border-right: 0px solid transparent;}

.table.wallet_history_table tbody,.myAccount_notification_title_row,.myAccount_bankingDetails_table {background: #f2f2f2;
color: rgba(0,0,0,1);
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;}

.wallet_btnAmount button {background: #fff;
color: #000;}

.wallet_btnAmount button.active {background: #0000f8;
color: #fff;}

.myAccount_notification_content {background: #fff;
color: #000;}

.myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {background: red;
color: #fff;}

.myAccount_userInfo_profile label {color: #f6f40d;}

.transactionTypeTab {background: #fff;
border-radius: 20px 20px 20px 20px;
padding: 0.5rem;}

.transactionTypeTab .transactionType_selection {background: #fff;
color: #000;
border-radius: 25px 25px 25px 25px;
border-top: 0px solid ;
border-bottom: 0px solid ;
border-left: 0px solid ;
border-right: 0px solid ;
margin-right: 1rem;
padding: 0.5rem 1rem;}

.transactionTypeTab .transactionType_selection.active {background: #00013e;
color: #f6f40d;
border-radius: 20px 20px 20px 20px;}

.wallet_body_container_m {padding: 0;}

.wallet_container_m {color: #fff;
border: 0;
filter: none;}

.wallet_container_m .wallet_container_wrap {background: transparent;}

.wallet_container_m .wallet_reminder_container {background: transparent;}

.walletBar_btnLogout {background: red;
color: #ffffff;
border-radius: 5px 5px 5px 5px;
border-top: 0px none ;
border-bottom: 0px none ;
border-left: 0px none ;
border-right: 0px none ;}

.depositBankImg img {border-radius: .375rem;
border-top: 1px solid #0000f8 ;
border-bottom: 1px solid #0000f8;
border-left: 1px solid #0000f8;
border-right: 1px solid #0000f8;}

.myFriendSelectionWrap {color: #fff;}

.friendList_group_title {background: #00013e;
color: #fff;}

.friendList_group_item_container {color: #fff;}

.friendDetail_info_phone, .friendDetail_info_status, .friendList_info_phone, .friendList_info_status {color: #fff;}

.friendRequest_tab {background: #00013e;
color: #fff;}

.friendRequest_tab .friendRequestTab_selection.active {background: linear-gradient(0deg, #00013e, #0000f8);
color: #fff;}

.friendDetail_group_item, .searchFriend_container {background: #00013e;
color: #fff;
border: 1px solid #000173;}

.friendRequest_tab .friendRequestTab_selection:hover {color: #fff;}

.wallet_container_m .realNameVerification_title {border-left: 3px solid #f6f40d;}

.realNameVerification_container .list-group-item {background: transparent;}

.coupon_body {background: #00013e;
padding: 0 0 3rem;}

#coupon_title {background: #00013e;
color: #fff;}

#coupon .filterTab {background: #fff;
border-radius: 25px 25px 25px 25px;
margin-top: 2rem;
padding: 0.5rem;}

#coupon .filterTab .filterTab_selection {padding: 0.5rem 1rem;}

#coupon .filterTab .filterTab_selection.active {background: #00013e;
color: #f6f40d;
border-radius: 20px 20px 20px 20px;}

.coupon_content_wrapper {color: #fff;}

.coupon_body_container {color: rgba(0,0,0,1);}

.button_couponClaim {border-radius: 5px 5px 5px 5px;
padding: 0.5rem;}

.coupon_expireTime {color: red;
margin-bottom: 1rem;}

.coupon_tnc {color: #0d6efd;}

.button_claimCoupon {border-radius: 5px;
margin-top: .75rem;
padding: .5rem;}


.App,.page{
    background:#00013e
}




